/**
 * report 각 함수내 catch 구문에서 아무 처리 하지않는 것은
 * datadogLog 모듈 이슈에 대한 트래킹을 하지 않겠다는 것을 의미합니다
 * @author Elie
 */
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { IS_LOCAL, IS_PROD_ZONE } from '@/constants/env';
import { User } from '@/types/Auth';
import {
  NATIVE_POST_MESSAGE_TYPE,
  isWebView,
  sendPostMessageToNative,
} from '@/utils/mobile/RNPlugins';

const getConditionalSampleRate = () => {
  const excludeUrlRegex = new RegExp(
    '(/content/magazine.*|/content/qna.*)',
    'i',
  );

  if (excludeUrlRegex.test(window.location.pathname)) {
    return 0;
  }

  if (window.location.search.indexOf('buzzvil') >= 0) {
    return 5;
  }

  return 100;
};

export const initDatadogMonitoring = () => {
  try {
    const conditionalSampleRate = getConditionalSampleRate();

    datadogLogs.init({
      clientToken: 'pub6ae158c35a813b91603f22d326fc6e7e',
      service: 'doctornow-user-web',
      // 고민포인트) getEnv()로 처리하는 것은 어떨까?
      env: IS_PROD_ZONE ? 'production' : 'development',
      forwardErrorsToLogs: IS_PROD_ZONE,
      sessionSampleRate: 100,
      silentMultipleInit: true,
      beforeSend: (event, context) => {
        /*
          https://github.com/DataDog/browser-sdk/issues/362#issuecomment-1758572518
          - status_code가 0인 경우는 네트워크 문제 및 연결 끊김 등으로 인한 에러로 판단하여 무시
        */
        if (event.error && event.http?.status_code === 0) {
          return false;
        }

        return !('isAborted' in context && context.isAborted);
      },
    });

    datadogRum.init({
      applicationId: '4d6d9edd-bec7-40c1-8811-33d44009cd1b',
      clientToken: 'pub97ed1e2ca8c7c834e9276da481afd54d',
      site: 'datadoghq.com',
      service: 'doctornow-user-web',
      env: IS_PROD_ZONE ? 'production' : 'development',
      // 고민포인트) PR버전/ 빌드버전 등으로 자동 generate 되는 건 어떨까? (어떤 배포 영향인지 알수 있을듯)
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: conditionalSampleRate,
      // sessionReplaySampleRate: 100을 하더라도  startSessionReplayRecording를 호출해야만 녹화가 시작됨
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  } catch (error) {}
};

export const setUserWithDatadogRum = (user: User) => {
  datadogRum.setUser({
    id: user.userId,
    email: user.email,
    name: user.name,
    age: user.birth,
  });
};

export const resetUserWithDatadogRum = () => {
  datadogRum.clearUser();
};

const getMessageContext = (messageContext: Record<string, unknown>) => {
  return JSON.parse(JSON.stringify(messageContext));
};
export const infoReport = (
  message: string,
  messageContext: Record<string, unknown>,
  error?: Error,
) => {
  try {
    if (IS_LOCAL) {
      console.log('report > reportInfo', message, messageContext, error);
    } else {
      datadogLogs.logger.info(
        message,
        getMessageContext(messageContext),
        error,
      );
    }
  } catch (error) {}
};

export const errorReport = (
  message: string,
  messageContext: Record<string, unknown>,
  error?: Error,
) => {
  try {
    if (IS_LOCAL) {
      console.log('report > reportError', message, messageContext, error);
    } else {
      datadogLogs.logger.error(
        message,
        getMessageContext(messageContext),
        error,
      );
    }
  } catch (error) {}
};

export const debugReport = (
  message: string,
  messageContext: Record<string, unknown>,
  error?: Error,
) => {
  try {
    if (IS_LOCAL) {
      console.log('report > reportDebug', message, messageContext, error);
    } else {
      datadogLogs.logger.debug(
        message,
        getMessageContext(messageContext),
        error,
      );
    }
  } catch (error) {}
};

export const addActionForRum = (
  actionName: string,
  context?: Record<string, unknown>,
) => {
  try {
    if (IS_LOCAL) {
      console.log('tracking > addAction', actionName, context);
    } else {
      if (isWebView()) {
        sendPostMessageToNative(NATIVE_POST_MESSAGE_TYPE.ADD_ACTION_FOR_RUM, {
          actionName,
          params: context,
        });
      } else {
        datadogRum.addAction(actionName, context);
      }
    }
  } catch (error) {}
};
