import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';

import WithHyperLinkComponent from '@/components/common/WithHyperLinkComponent';
import Loader from '@/components/molecules/Loader';
import { useGetBestQuestionMobileCardsQuery } from '@/hooks/qna/mobile/qna.gql.impl';
import { qnaBestQuestionsService } from '@/services/qna/mobile';
import theme from '@/styles/theme';
import { ROUTES } from '@/types/common';
import { getPageName, mobileLogger } from '@/utils/logger';

interface Props {
  marginTop?: number;
}
const BestQuestions = ({ marginTop = 0 }: Props) => {
  const router = useRouter();
  const { data: bestQuestionsData } = useGetBestQuestionMobileCardsQuery(
    {},
    {
      select: (data) => qnaBestQuestionsService(data),
    },
  );

  const onClickLinkLog = () => {
    mobileLogger({
      logger: {
        eventName: 'post_item_click',
        pageName: getPageName(router),
        payload: {
          platform: 'web',
        },
      },
    });
  };

  return (
    <Wrapper marginTop={marginTop}>
      <Title>실시간 상담 베스트</Title>
      {bestQuestionsData?.bestQuestionCards ? (
        <List>
          {bestQuestionsData?.bestQuestionCards.map((question) => (
            <Item
              key={`best-question-${question.question.questionPid}`}
              role="button"
              onClick={onClickLinkLog}
            >
              <WithHyperLinkComponent
                href={`${ROUTES.QNA.DETAIL.path}/${question.question.questionPid}`}
              >
                <div>
                  <QuestionMark>Q</QuestionMark>
                  <p>{question.question.title}</p>
                </div>
              </WithHyperLinkComponent>
            </Item>
          ))}
        </List>
      ) : (
        <Loader />
      )}
    </Wrapper>
  );
};

export default BestQuestions;

const Wrapper = styled.div<{ marginTop: number }>`
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop}px;
`;

const Title = styled.div`
  color: ${theme.color.GRAY700};
  ${theme.typography.UI17SB};
  margin-bottom: 4px;
  padding: 0 12px;
`;

const List = styled.ul`
  width: 100%;
`;

const QuestionMark = styled.div`
  ${theme.typography.UI17M};
  color: ${theme.color.GRAY200};
  margin-right: 12px;
`;
const Item = styled.li`
  width: 100%;
  padding: 12px;
  border-radius: 8px;

  & > a > div {
    display: flex;
    min-width: 0;
    align-items: center;
    ${theme.typography.UI15M};
    color: ${theme.color.GRAY600};
    white-space: nowrap;
    text-overflow: ellipsis;

    & > p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &:hover {
    background-color: ${theme.color.GRAY30};
  }

  &:active {
    background-color: ${theme.color.GRAY50};
  }
`;
