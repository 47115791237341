import { ElementType } from 'react';

import Bc from '@/assets/cardCompany/bc.svg';
import Citi from '@/assets/cardCompany/citi.svg';
import Etc from '@/assets/cardCompany/etc.svg';
import Hana from '@/assets/cardCompany/hana.svg';
import Hyundai from '@/assets/cardCompany/hyundai.svg';
import Jeju from '@/assets/cardCompany/jeju.svg';
import KakaoBank from '@/assets/cardCompany/kakaoBank.svg';
import KakaoPay from '@/assets/cardCompany/kakaoPay.svg';
import Kookmin from '@/assets/cardCompany/kookmin.svg';
import Lotte from '@/assets/cardCompany/lotte.svg';
import Nh from '@/assets/cardCompany/nh.svg';
import Postoffice from '@/assets/cardCompany/postoffice.svg';
import Samsung from '@/assets/cardCompany/samsung.svg';
import Shinhan from '@/assets/cardCompany/shinhan.svg';
import Soohyup from '@/assets/cardCompany/soohyup.svg';
import Woorri from '@/assets/cardCompany/woorri.svg';
import BcCard from '@/assets/cards/bcCard.svg';
import CitiCard from '@/assets/cards/citiCard.svg';
import DefaultCard from '@/assets/cards/defaultCard.svg';
import HanaCard from '@/assets/cards/hanaCard.svg';
import HyundaiCard from '@/assets/cards/hyundaiCard.svg';
import JejuCard from '@/assets/cards/jejuCard.svg';
import KakaoBankCard from '@/assets/cards/kakaoBankCard.svg';
import KakaoPayMoney from '@/assets/cards/kakaoPayMoney.svg';
import KookminCard from '@/assets/cards/kookminCard.svg';
import LotteCard from '@/assets/cards/lotteCard.svg';
import NonghyupCard from '@/assets/cards/nonghyupCard.svg';
import PostofficeCard from '@/assets/cards/postOfficeCard.svg';
import SamsungCard from '@/assets/cards/samsungCard.svg';
import ShinhanCard from '@/assets/cards/shinhanCard.svg';
import SoohyupCard from '@/assets/cards/soohyupCard.svg';
import WooriCard from '@/assets/cards/woorriCard.svg';
import { MatchCardCompany } from '@/models/common';
import theme from '@/styles/theme';

export const DEFAULT_CARD_ICON: MatchCardCompany = {
  label: '',
  icon: Etc,
  bgColor: '#3D4551',
  textColor: theme.color.WHITE,
  match: [],
};

export const CARD_ICON_V2: MatchCardCompany[] = [
  {
    label: 'KB국민카드',
    icon: <Kookmin />,
    bgColor: '#7C7366',
    textColor: theme.color.WHITE,
    match: ['KB', '국민'],
  },
  {
    label: '씨티카드',
    icon: <Citi />,
    bgColor: '#ECECEC',
    textColor: theme.color.GRAY900,
    match: ['씨티'],
  },
  {
    label: '삼성카드',
    icon: <Samsung />,
    bgColor: '#1328A1',
    textColor: theme.color.WHITE,
    match: ['삼성'],
  },
  {
    label: '현대카드',
    icon: <Hyundai />,
    bgColor: theme.color.BLACK,
    textColor: theme.color.WHITE,
    match: ['현대'],
  },
  {
    label: 'NH카드',
    icon: <Nh />,
    bgColor: '#FDB913',
    textColor: theme.color.WHITE,
    match: ['NH'],
  },
  {
    label: 'KEB하나카드',
    icon: <Hana />,
    bgColor: '#008E90',
    textColor: theme.color.WHITE,
    match: ['하나'],
  },
  {
    label: '우리카드',
    icon: <Woorri />,
    bgColor: '#004B9D',
    textColor: theme.color.WHITE,
    match: ['우리'],
  },
  {
    label: '신한카드',
    icon: <Shinhan />,
    bgColor: '#0046FF',
    textColor: theme.color.WHITE,
    match: ['신한'],
  },
  {
    label: 'BC카드',
    icon: <Bc />,
    bgColor: '#E83E44',
    textColor: theme.color.GRAY900,
    match: ['BC', '비씨'],
  },
  {
    label: '수협은행카드',
    icon: <Soohyup />,
    bgColor: '#0069B4',
    textColor: theme.color.GRAY900,
    match: ['수협'],
  },
  {
    label: '우체국카드',
    icon: <Postoffice />,
    bgColor: '#E72410',
    textColor: theme.color.GRAY900,
    match: ['우체국'],
  },
  {
    label: '롯데카드',
    icon: <Lotte />,
    bgColor: '#E60012',
    textColor: theme.color.WHITE,
    match: ['롯데'],
  },
  {
    label: '제주카드',
    icon: <Jeju />,
    bgColor: '#1A44F5',
    textColor: theme.color.GRAY900,
    match: ['제주'],
  },
  {
    label: '카카오페이머니',
    icon: <KakaoPay />,
    bgColor: '#FFE300',
    textColor: theme.color.GRAY900,
    match: ['카카오페이머니'],
  },
  {
    label: '카카오뱅크',
    icon: <KakaoBank />,
    bgColor: '#FFE300',
    textColor: theme.color.GRAY900,
    match: ['카카오뱅크체크', '카카오뱅크mini', '카카오뱅크'],
  },
  DEFAULT_CARD_ICON,
];

interface CardIcon {
  cardIcon: ElementType;
  match: string[];
}

export const CARD_ICON: CardIcon[] = [
  {
    cardIcon: KookminCard,
    match: ['KB', '국민'],
  },
  {
    cardIcon: CitiCard,
    match: ['씨티'],
  },
  {
    cardIcon: SamsungCard,
    match: ['삼성'],
  },
  {
    cardIcon: HyundaiCard,
    match: ['현대'],
  },
  {
    cardIcon: NonghyupCard,
    match: ['NH'],
  },
  {
    cardIcon: HanaCard,
    match: ['하나'],
  },
  {
    cardIcon: WooriCard,
    match: ['우리'],
  },
  {
    cardIcon: ShinhanCard,
    match: ['신한'],
  },
  {
    cardIcon: BcCard,
    match: ['BC', '비씨'],
  },
  {
    cardIcon: SoohyupCard,
    match: ['수협'],
  },
  {
    cardIcon: PostofficeCard,
    match: ['우체국'],
  },
  {
    cardIcon: LotteCard,
    match: ['롯데'],
  },
  {
    cardIcon: JejuCard,
    match: ['제주'],
  },
  {
    cardIcon: KakaoPayMoney,
    match: ['카카오페이머니'],
  },
  {
    cardIcon: KakaoBankCard,
    match: ['카카오뱅크체크', '카카오뱅크mini', '카카오뱅크'],
  },
  {
    cardIcon: DefaultCard,
    match: [],
  },
];

export const getCardIcon = (cardName: string) => {
  const matchingCardIcon = CARD_ICON.find(
    (cardIcon) =>
      cardIcon.match.find((it) => it === cardName) ||
      cardIcon.match.find((it) => it === cardName.substring(0, 2)),
  );

  return matchingCardIcon?.cardIcon ?? DefaultCard;
};
