import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { DIRECTION } from '@/types/common';

/**
 * @param size
 * @default 20
 *
 * @param direction
 * @default DIRECTION.RIGHT
 */
interface ArrowProps {
  size?: number;
  direction?: DIRECTION;
  color?: string;
}
const ArrowsButtonMin: FunctionComponent<ArrowProps> = ({
  size = 16,
  direction = DIRECTION.RIGHT,
  color = '#8D9297',
}) => {
  return (
    <DirectionSVG
      direction={direction}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.2998 11.625L8.72168 8.28125L5.2998 4.95312L6.2373 4L10.6436 8.28125L6.2373 12.5781L5.2998 11.625Z"
        fill={color}
      />
    </DirectionSVG>
  );
};

export default ArrowsButtonMin;

const DirectionSVG = styled.svg<{ direction: DIRECTION }>`
  transition: transform 0.1s linear;
  transform: rotate(
    ${({ direction }) =>
      direction === DIRECTION.TOP
        ? -90
        : direction === DIRECTION.LEFT
        ? -180
        : direction === DIRECTION.BOTTOM
        ? 90
        : 0}deg
  );
`;
