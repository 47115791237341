import styled, { keyframes } from 'styled-components';

import theme from '@/styles/theme';

const Rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const Stretch = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -124px;
  }
`;

export const SpinnerContainer = styled.svg<{ size?: number }>`
  ${({ size = 40 }) => ({
    width: `${size}px`,
    height: `${size}px`,
  })}
  vertical-align: middle;
  transform-origin: center;
  animation: ${Rotate} 2s linear infinite;
`;

export const SpinnerCircle = styled.circle<{ color?: string }>`
  fill: none;
  stroke: ${({ color = theme.color.PRIMARY700 }) => color};
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: ${Stretch} calc(2s * 0.75) ease-in-out infinite;
`;
