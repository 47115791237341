export default {
  title: '닥터나우 - 대한민국 대표 비대면진료 앱',
  description:
    '비대면진료 어플 닥터나우로 언제든 원격진료 가능! 탈모약, 소아과약, 여드름약도 비대면 약처방 가능해요. 문 연 약국 찾기, 약 배송도 이용해 보세요. - 아플 땐 닥터나우',
  canonical: 'https://doctornow.co.kr/',
  author: '닥터나우',
  copyright: '닥터나우',
  openGraph: {
    type: 'website',
    locale: 'ko_KR',
    url: 'https://doctornow.co.kr/',
    title: '닥터나우 - 대한민국 대표 비대면진료 앱',
    site_name: 'doctornow',
    description:
      '비대면진료 어플 닥터나우로 언제든 원격진료 가능! 탈모약, 소아과약, 여드름약도 비대면 약처방 가능해요. 문 연 약국 찾기, 약 배송도 이용해 보세요. - 아플 땐 닥터나우',
    images: [
      {
        url: 'https://doctornow-front-images.s3.ap-northeast-2.amazonaws.com/og-main.png',
        width: 600,
        height: 315,
        alt: '닥터나우',
      },
    ],
  },
  twitter: {
    handle: '@doctornow',
    site: '@doctornow.co.kr',
    cardType: 'summary_large_image',
  },
};
