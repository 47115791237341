import { FunctionComponent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { DefaultButtonStyled } from '@/components/atom/buttons/DefaultButton';
import { BasicModalProps } from '@/components/molecules/Modal/BasicModal';
import Overlay from '@/components/molecules/Overlay';

import { MODAL_BUTTON_TYPE } from '..';

export interface AlertModalProps extends Omit<BasicModalProps, 'body'> {
  index: number;
  title: ReactNode;
  message?: ReactNode;
}

const AlertModal: FunctionComponent<AlertModalProps> = ({
  index,
  title,
  message,
  buttons,
  onClickOverlay,
}) => {
  return (
    <Overlay index={index} onClickOverlay={onClickOverlay}>
      <AlertModalContainer>
        <AlertModalContent>
          <AlertModalTitle>{title}</AlertModalTitle>
          {message && <AlertModalMessage>{message}</AlertModalMessage>}
        </AlertModalContent>
        <AlertModalButtonWrapper>
          {buttons.map((button, index) => {
            const { type, label, labelColor, onClick } = button;
            return (
              <AlertModalButton
                key={`alertModalButton-${index}`}
                $type={type || MODAL_BUTTON_TYPE.DEFAULT}
                labelColor={labelColor}
                onClick={onClick}
              >
                {label || '취소'}
              </AlertModalButton>
            );
          })}
        </AlertModalButtonWrapper>
      </AlertModalContainer>
    </Overlay>
  );
};

export default AlertModal;

export const AlertModalContainer = styled.div`
  width: 296px;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 8%);

  ${({ theme }) => `
    background-color: ${theme.color.WHITE};
  `}
`;

export const AlertModalContent = styled.div`
  margin-bottom: 24px;
`;

export const AlertModalTitle = styled.h3`
  ${({ theme }) => `
    ${theme.typography.UI18SB};
    color: ${theme.color.GRAY700};
    `};
`;

export const AlertModalMessage = styled.div`
  margin-top: 8px;
  ${({ theme }) => css`
    ${theme.typography.UI14M};
    color: ${theme.color.GRAY500};
  `};
`;

export const AlertModalButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  button + button {
    margin-left: 8px;
  }
`;

const DefaultModalButton = css`
  ${({ theme }) => `
    ${theme.typography.UI16M};
    background: ${theme.color.GRAY50};
    
    &:active {
      background: ${theme.color.GRAY100};
    };
  `};
`;

const BoldModalButton = css`
  ${({ theme }) => `
    color: ${theme.color.WHITE};
    background: ${theme.color.GRAY700};
    
    &:active {
      background: ${theme.color.GRAY800};
    };
  `};
`;

const PrimaryModalButton = css`
  ${({ theme }) => `
    color: ${theme.color.WHITE};
    background: ${theme.color.PRIMARY500};

    &:active {
      background: ${theme.color.PRIMARY700};
    };
  `};
`;

const DangerModalButton = css`
  ${({ theme }) => `
    color: ${theme.color.WHITE};
    background: ${theme.color.RED500};

    &:active {
      background: ${theme.color.RED700};
    };
  `};
`;

export const AlertModalButton = styled(DefaultButtonStyled)<{
  $type?: MODAL_BUTTON_TYPE;
  labelColor?: string;
}>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 48px;
  border-radius: 8px;

  ${({ theme }) => `
    ${theme.typography.UI16SB};
    `};

  ${({ $type }) => {
    switch ($type) {
      case MODAL_BUTTON_TYPE.DEFAULT:
        return DefaultModalButton;
      case MODAL_BUTTON_TYPE.BOLD:
        return BoldModalButton;
      case MODAL_BUTTON_TYPE.PRIMARY:
        return PrimaryModalButton;
      case MODAL_BUTTON_TYPE.DANGER:
        return DangerModalButton;
    }
  }};
`;
