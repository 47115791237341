import { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { DefaultButtonStyled } from '@/components/atom/buttons/DefaultButton';
import { ModalButtonProps } from '@/components/molecules/Modal';
import Overlay from '@/components/molecules/Overlay';

export interface BasicModalProps {
  index: number;
  body: ReactNode;
  buttons: Array<ModalButtonProps>;
  onClickOverlay?: () => void;
}

const BasicModal: FunctionComponent<BasicModalProps> = ({
  index,
  body,
  buttons,
  onClickOverlay,
}) => {
  return (
    <Overlay index={index} onClickOverlay={onClickOverlay}>
      <BasicModalContainer>
        {body}
        <BasicModalButtonWrapper>
          {buttons.map((button, index) => {
            const { label, labelColor, onClick } = button;
            return (
              <BasicModalButton
                key={`basicModalButton-${index}`}
                labelColor={labelColor}
                onClick={() => onClick()}
              >
                {label || '취소'}
              </BasicModalButton>
            );
          })}
        </BasicModalButtonWrapper>
      </BasicModalContainer>
    </Overlay>
  );
};

export default BasicModal;

const BasicModalContainer = styled.div`
  min-width: 360px;
  max-width: 95vw;

  img {
    display: block;
    width: 100%;
  }
`;

const BasicModalButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #efefef;
`;

const BasicModalButton = styled(DefaultButtonStyled)<{
  labelColor?: string;
}>`
  ${({ theme }) => theme.typography.UI16R};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 60px;
  border-right: 1px solid #efefef;
  color: ${({ theme, labelColor }) =>
    labelColor ? labelColor : theme.color.BLACK};

  &:last-child {
    border-right: none;
  }
`;
