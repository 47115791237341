import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';

import ArrowsButtonMin from '@/components/atom/icon/Arrow/ArrowsButtonMin';
import BestQuestions from '@/components/templates/communication/Qna/BestQuestions';
import theme from '@/styles/theme';
import { getPageName, mobileLogger } from '@/utils/logger';

const Link = 'https://abr.ge/oqmbrwn';
const DefaultGlobalSideBar = () => {
  const router = useRouter();
  const onClickBanner = async () => {
    router.push(Link);
    mobileLogger({
      logger: {
        eventName: 'right_side_banner_app_download_click',
        pageName: getPageName(router),
        payload: {
          platform: 'web',
        },
      },
    });
  };

  return (
    <Wrapper>
      <DefaultBanner role="button" onClick={onClickBanner}>
        <TextWrapper>
          닥터나우 앱에서
          <br />
          언제 어디서나 편하게
          <br />
          비대면 진료 받아보세요!
          <p>365일 24시간 내내 가능해요</p>
          <button>
            지금 다운로드 받기
            <ArrowsButtonMin color={theme.color.PRIMARY700} />
          </button>
        </TextWrapper>
        <ImageWrapper>
          <Image
            layout="fill"
            src={
              'https://file.doctornow.co.kr/homepage/banners/app_install_banner_240731.png'
            }
            alt="소개 이미지"
          />
        </ImageWrapper>
      </DefaultBanner>
      <BestQuestions marginTop={32} />
    </Wrapper>
  );
};

export default DefaultGlobalSideBar;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 296px;
  min-width: 296px;
  padding: 24px 12px;
  position: sticky;
  top: 0;
  align-self: flex-start;
`;

const DefaultBanner = styled.div`
  width: calc(100% - 12px);
  background-color: ${theme.color.PRIMARY200};
  border-radius: 8px;
  margin: 0 12px;
`;

const TextWrapper = styled.div`
  padding: 24px;
  width: fit-content;
  color: ${theme.color.GRAY800};
  ${theme.typography.UI20SB};

  & > p {
    color: ${theme.color.GRAY500};
    ${theme.typography.UI15M};
  }

  & > button {
    display: flex;
    margin-top: 10px;
    align-items: center;
    border: none;
    color: ${theme.color.PRIMARY700};
    ${theme.typography.UI14SB};
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 180px;
  position: relative;
`;
