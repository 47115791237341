import { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import ToastIcon from '@/components/atom/icon/ToastIcon';
import { Tablet } from '@/styles/layout';
import { Toast } from '@/types/Toast';

interface Props {
  toast: Toast;
  index: number;
}
const ToastItem = ({ toast, index }: Props) => {
  const [unmount, setUnmount] = useState<boolean>(false);
  const animationEnd = () => {
    setTimeout(() => {
      setUnmount(true);
    }, toast.props.visibleTime ?? 2000);
  };

  return (
    <ToastItemWrapper
      key={`toast-${toast.id}`}
      index={index + 1}
      unmount={unmount}
      onAnimationEnd={animationEnd}
    >
      <ToastIcon /> {toast.props.message}
    </ToastItemWrapper>
  );
};

export default ToastItem;

const TopDown = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const BottomUp = (index: number) => keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc((-100% - 32px) * ${index + 1}));
  }
`;

const ToastItemWrapper = styled.div<{ unmount: boolean; index: number }>`
  ${({ theme }) => theme.typography.UI16SB};
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 24px 48px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.GREEN500};
  text-align: left;
  color: ${({ theme }) => theme.color.WHITE};
  animation: ${({ unmount, index }) => (unmount ? BottomUp(index) : TopDown)}
    0.4s ease-in-out forwards;
  white-space: pre-wrap;

  svg {
    margin-right: 8px;
  }

  @media screen and (max-width: ${Tablet}) {
    ${({ theme }) => theme.typography.UI14SB};
    padding: 16px 24px;
    white-space: nowrap;
  }
`;
