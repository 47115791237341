import { FunctionComponent, ReactNode, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import AlertModal, {
  AlertModalProps,
} from '@/components/molecules/Modal/AlertModal';
import BasicModal, {
  BasicModalProps,
} from '@/components/molecules/Modal/BasicModal';
import { modalState } from '@/recoil/atoms/common/modal';
import { MODAL_TYPE } from '@/types/modal';

export const MODAL_BUTTON_TYPE = {
  DEFAULT: 'default',
  BOLD: 'bold',
  PRIMARY: 'primary',
  DANGER: 'danger',
} as const;
export type MODAL_BUTTON_TYPE =
  typeof MODAL_BUTTON_TYPE[keyof typeof MODAL_BUTTON_TYPE];

export interface ModalButtonProps {
  type?: MODAL_BUTTON_TYPE;
  label?: ReactNode;
  labelColor?: string;
  onClick: () => void;
}

export type ModalProps =
  | Omit<BasicModalProps, 'index'>
  | Omit<AlertModalProps, 'index'>;

const Modal: FunctionComponent = () => {
  const modals = useRecoilValue(modalState);

  function handleTouchMove(event: TouchEvent) {
    event.preventDefault();
  }

  useEffect(() => {
    if (modals.length > 0) {
      document.body.style.overflow = 'hidden';
      document.body.addEventListener('touchmove', handleTouchMove, false);
    } else {
      document.body.style.overflow = 'unset';
      document.body.removeEventListener('touchmove', handleTouchMove, false);
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.body.removeEventListener('touchmove', handleTouchMove, false);
    };
  }, [modals]);

  return (
    <>
      {modals.map(({ index, type, props }) => {
        switch (type) {
          case MODAL_TYPE.ALERT:
            return (
              <AlertModal
                {...(props as AlertModalProps)}
                index={index}
                key={`alertModal-${index}`}
              />
            );
          case MODAL_TYPE.BASIC:
          default:
            return (
              <BasicModal
                {...(props as BasicModalProps)}
                index={index}
                key={`customModal-${index}`}
              />
            );
        }
      })}
    </>
  );
};

export default Modal;
