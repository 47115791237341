import * as Types from '../../../types/bff/gql-schema';

import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { gqlFetcher } from '@/utils/fetcher';

export const GetNewsQuestionDocument = `
    query GetNewsQuestion($newestQuestionCardCursorPageRequestInput: NewestQuestionCardCursorPageRequestInput!) {
  newestQuestionCardCursorPage(request: $newestQuestionCardCursorPageRequestInput) {
    elements {
      answeredStatus
      question {
        status
        aiAnswerStatus
        accessibleType
        attachments {
          blindType
          questionAttachmentId
        }
        category {
          name
          questionCategoryId
          iconImageUrl
        }
        tags {
          name
        }
        content
        isBlockedByMe
        questionPid
        title
      }
    }
  }
}
    `;
export const useGetNewsQuestionQuery = <
      TData = Types.GetNewsQuestionQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetNewsQuestionQueryVariables,
      options?: UseQueryOptions<Types.GetNewsQuestionQuery, TError, TData>
    ) =>
    useQuery<Types.GetNewsQuestionQuery, TError, TData>(
      ['GetNewsQuestion', variables],
      gqlFetcher<Types.GetNewsQuestionQuery, Types.GetNewsQuestionQueryVariables>(GetNewsQuestionDocument, variables),
      options
    );

useGetNewsQuestionQuery.getKey = (variables: Types.GetNewsQuestionQueryVariables) => ['GetNewsQuestion', variables];
;

useGetNewsQuestionQuery.fetcher = (variables: Types.GetNewsQuestionQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetNewsQuestionQuery, Types.GetNewsQuestionQueryVariables>(GetNewsQuestionDocument, variables, options);
export const GetNewsMyQuestionDocument = `
    query GetNewsMyQuestion($myQuestionCardCursorPageRequestInput: MyQuestionCardCursorPageRequestInput!) {
  myQuestionCardCursorPage(request: $myQuestionCardCursorPageRequestInput) {
    elements {
      answeredStatus
      question {
        status
        aiAnswerStatus
        accessibleType
        attachments {
          blindType
          questionAttachmentId
        }
        category {
          name
          questionCategoryId
          iconImageUrl
        }
        tags {
          name
        }
        content
        questionPid
        title
      }
    }
  }
}
    `;
export const useGetNewsMyQuestionQuery = <
      TData = Types.GetNewsMyQuestionQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetNewsMyQuestionQueryVariables,
      options?: UseQueryOptions<Types.GetNewsMyQuestionQuery, TError, TData>
    ) =>
    useQuery<Types.GetNewsMyQuestionQuery, TError, TData>(
      ['GetNewsMyQuestion', variables],
      gqlFetcher<Types.GetNewsMyQuestionQuery, Types.GetNewsMyQuestionQueryVariables>(GetNewsMyQuestionDocument, variables),
      options
    );

useGetNewsMyQuestionQuery.getKey = (variables: Types.GetNewsMyQuestionQueryVariables) => ['GetNewsMyQuestion', variables];
;

useGetNewsMyQuestionQuery.fetcher = (variables: Types.GetNewsMyQuestionQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetNewsMyQuestionQuery, Types.GetNewsMyQuestionQueryVariables>(GetNewsMyQuestionDocument, variables, options);
export const GetBestTagsDocument = `
    query getBestTags($size: Int!) {
  bestTags(size: $size) {
    name
    questionTagId
  }
}
    `;
export const useGetBestTagsQuery = <
      TData = Types.GetBestTagsQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetBestTagsQueryVariables,
      options?: UseQueryOptions<Types.GetBestTagsQuery, TError, TData>
    ) =>
    useQuery<Types.GetBestTagsQuery, TError, TData>(
      ['getBestTags', variables],
      gqlFetcher<Types.GetBestTagsQuery, Types.GetBestTagsQueryVariables>(GetBestTagsDocument, variables),
      options
    );

useGetBestTagsQuery.getKey = (variables: Types.GetBestTagsQueryVariables) => ['getBestTags', variables];
;

useGetBestTagsQuery.fetcher = (variables: Types.GetBestTagsQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetBestTagsQuery, Types.GetBestTagsQueryVariables>(GetBestTagsDocument, variables, options);
export const GetBestQuestionMobileCardsDocument = `
    query getBestQuestionMobileCards {
  bestQuestionCards {
    answeredStatus
    answererCount
    answererProfileImageUrls
    question {
      status
      attachments {
        blindType
        questionAttachmentId
      }
      category {
        iconImageUrl
        name
      }
      tags {
        name
      }
      content
      questionPid
      title
    }
  }
}
    `;
export const useGetBestQuestionMobileCardsQuery = <
      TData = Types.GetBestQuestionMobileCardsQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.GetBestQuestionMobileCardsQueryVariables,
      options?: UseQueryOptions<Types.GetBestQuestionMobileCardsQuery, TError, TData>
    ) =>
    useQuery<Types.GetBestQuestionMobileCardsQuery, TError, TData>(
      variables === undefined ? ['getBestQuestionMobileCards'] : ['getBestQuestionMobileCards', variables],
      gqlFetcher<Types.GetBestQuestionMobileCardsQuery, Types.GetBestQuestionMobileCardsQueryVariables>(GetBestQuestionMobileCardsDocument, variables),
      options
    );

useGetBestQuestionMobileCardsQuery.getKey = (variables?: Types.GetBestQuestionMobileCardsQueryVariables) => variables === undefined ? ['getBestQuestionMobileCards'] : ['getBestQuestionMobileCards', variables];
;

useGetBestQuestionMobileCardsQuery.fetcher = (variables?: Types.GetBestQuestionMobileCardsQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetBestQuestionMobileCardsQuery, Types.GetBestQuestionMobileCardsQueryVariables>(GetBestQuestionMobileCardsDocument, variables, options);
export const GetAttachmentOriginPreSignedUrlDocument = `
    query getAttachmentOriginPreSignedUrl($questionAttachmentId: ID!) {
  attachmentDownloadPreSignedUrl(questionAttachmentId: $questionAttachmentId)
}
    `;
export const useGetAttachmentOriginPreSignedUrlQuery = <
      TData = Types.GetAttachmentOriginPreSignedUrlQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetAttachmentOriginPreSignedUrlQueryVariables,
      options?: UseQueryOptions<Types.GetAttachmentOriginPreSignedUrlQuery, TError, TData>
    ) =>
    useQuery<Types.GetAttachmentOriginPreSignedUrlQuery, TError, TData>(
      ['getAttachmentOriginPreSignedUrl', variables],
      gqlFetcher<Types.GetAttachmentOriginPreSignedUrlQuery, Types.GetAttachmentOriginPreSignedUrlQueryVariables>(GetAttachmentOriginPreSignedUrlDocument, variables),
      options
    );

useGetAttachmentOriginPreSignedUrlQuery.getKey = (variables: Types.GetAttachmentOriginPreSignedUrlQueryVariables) => ['getAttachmentOriginPreSignedUrl', variables];
;

useGetAttachmentOriginPreSignedUrlQuery.fetcher = (variables: Types.GetAttachmentOriginPreSignedUrlQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetAttachmentOriginPreSignedUrlQuery, Types.GetAttachmentOriginPreSignedUrlQueryVariables>(GetAttachmentOriginPreSignedUrlDocument, variables, options);
export const GetAttachmentThumbnailPreSignedUrlDocument = `
    query getAttachmentThumbnailPreSignedUrl($questionAttachmentId: ID!) {
  attachmentThumbnailDownloadPreSignedUrl(
    questionAttachmentId: $questionAttachmentId
  )
}
    `;
export const useGetAttachmentThumbnailPreSignedUrlQuery = <
      TData = Types.GetAttachmentThumbnailPreSignedUrlQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetAttachmentThumbnailPreSignedUrlQueryVariables,
      options?: UseQueryOptions<Types.GetAttachmentThumbnailPreSignedUrlQuery, TError, TData>
    ) =>
    useQuery<Types.GetAttachmentThumbnailPreSignedUrlQuery, TError, TData>(
      ['getAttachmentThumbnailPreSignedUrl', variables],
      gqlFetcher<Types.GetAttachmentThumbnailPreSignedUrlQuery, Types.GetAttachmentThumbnailPreSignedUrlQueryVariables>(GetAttachmentThumbnailPreSignedUrlDocument, variables),
      options
    );

useGetAttachmentThumbnailPreSignedUrlQuery.getKey = (variables: Types.GetAttachmentThumbnailPreSignedUrlQueryVariables) => ['getAttachmentThumbnailPreSignedUrl', variables];
;

useGetAttachmentThumbnailPreSignedUrlQuery.fetcher = (variables: Types.GetAttachmentThumbnailPreSignedUrlQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetAttachmentThumbnailPreSignedUrlQuery, Types.GetAttachmentThumbnailPreSignedUrlQueryVariables>(GetAttachmentThumbnailPreSignedUrlDocument, variables, options);
export const GetMyAnswerCursorPageDocument = `
    query getMyAnswerCursorPage($myAnswerCursorPageRequestInput: MyAnswerCursorPageRequestInput!) {
  myAnswerCursorPage(request: $myAnswerCursorPageRequestInput) {
    elements {
      answerPid
      answerer {
        affiliatedInstitutionName
        answererId
        answererType
        name
        professionalFields {
          name
          professionalFieldId
          professionalFieldType
        }
        profileImageUrl
      }
      content
      questionPid
    }
  }
}
    `;
export const useGetMyAnswerCursorPageQuery = <
      TData = Types.GetMyAnswerCursorPageQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetMyAnswerCursorPageQueryVariables,
      options?: UseQueryOptions<Types.GetMyAnswerCursorPageQuery, TError, TData>
    ) =>
    useQuery<Types.GetMyAnswerCursorPageQuery, TError, TData>(
      ['getMyAnswerCursorPage', variables],
      gqlFetcher<Types.GetMyAnswerCursorPageQuery, Types.GetMyAnswerCursorPageQueryVariables>(GetMyAnswerCursorPageDocument, variables),
      options
    );

useGetMyAnswerCursorPageQuery.getKey = (variables: Types.GetMyAnswerCursorPageQueryVariables) => ['getMyAnswerCursorPage', variables];
;

useGetMyAnswerCursorPageQuery.fetcher = (variables: Types.GetMyAnswerCursorPageQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetMyAnswerCursorPageQuery, Types.GetMyAnswerCursorPageQueryVariables>(GetMyAnswerCursorPageDocument, variables, options);
export const GetQuestionDetailDocument = `
    query getQuestionDetail($questionPid: ID!, $size: Int) {
  question(questionPid: $questionPid) {
    accessibleType
    paymentInfo {
      discountAmount
      paidAmount
      paidAt
      payTryCount
      price
      usedPoint
    }
    paymentTotal {
      currentAmount
      payTrackingNumber
      reason
      status
      userPaymentId
      userPayment {
        cardName
        cardNumber
        id
      }
    }
    status
    answererCount
    answeredStatus
    answers {
      answerPid
      answerer {
        affiliatedInstitutionName
        answererId
        answererPublicId
        answererType
        name
        profileImageUrl
        affiliation {
          address
          affiliationPid
          imageUrl
          isBookable
          isOpen
          name
        }
      }
      content
      createdAt
      dislikeCount
      footer
      header
      isBlockedByMe
      likeCount
      myLikeType
      reviews {
        id
        answerPid
        rating
        content
        createdAt
        updatedAt
        reviewTags {
          id
          text
        }
      }
      recommendedMagazineGraph {
        description
        magazinePid
        title
        titleImageUrl
      }
    }
    attachments {
      blindType
      questionAttachmentId
    }
    category {
      name
      questionCategoryId
      type
    }
    content
    createdAt
    isBlockedByMe
    isLikedByMe
    isMine
    likeCount
    questionPid
    showUserPersonalInfo
    tags {
      name
    }
    title
    user {
      name
      userId
      birth
      gender
    }
  }
  nearSimpleQuestions(request: {questionPid: $questionPid, size: $size}) {
    questionPid
  }
}
    `;
export const useGetQuestionDetailQuery = <
      TData = Types.GetQuestionDetailQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetQuestionDetailQueryVariables,
      options?: UseQueryOptions<Types.GetQuestionDetailQuery, TError, TData>
    ) =>
    useQuery<Types.GetQuestionDetailQuery, TError, TData>(
      ['getQuestionDetail', variables],
      gqlFetcher<Types.GetQuestionDetailQuery, Types.GetQuestionDetailQueryVariables>(GetQuestionDetailDocument, variables),
      options
    );

useGetQuestionDetailQuery.getKey = (variables: Types.GetQuestionDetailQueryVariables) => ['getQuestionDetail', variables];
;

useGetQuestionDetailQuery.fetcher = (variables: Types.GetQuestionDetailQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetQuestionDetailQuery, Types.GetQuestionDetailQueryVariables>(GetQuestionDetailDocument, variables, options);
export const SetBlockingAccountDocument = `
    mutation setBlockingAccount($blockingAccountMutationRequest: BlockingAccountMutationRequestInput!) {
  upsertBlockingAccount(
    blockingAccountMutationRequest: $blockingAccountMutationRequest
  )
}
    `;
export const useSetBlockingAccountMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetBlockingAccountMutation, TError, Types.SetBlockingAccountMutationVariables, TContext>) =>
    useMutation<Types.SetBlockingAccountMutation, TError, Types.SetBlockingAccountMutationVariables, TContext>(
      ['setBlockingAccount'],
      (variables?: Types.SetBlockingAccountMutationVariables) => gqlFetcher<Types.SetBlockingAccountMutation, Types.SetBlockingAccountMutationVariables>(SetBlockingAccountDocument, variables)(),
      options
    );
useSetBlockingAccountMutation.fetcher = (variables: Types.SetBlockingAccountMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.SetBlockingAccountMutation, Types.SetBlockingAccountMutationVariables>(SetBlockingAccountDocument, variables, options);
export const SetReportQuestionDocument = `
    mutation setReportQuestion($request: QuestionReportRequestInput!) {
  reportQuestion(request: $request)
}
    `;
export const useSetReportQuestionMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetReportQuestionMutation, TError, Types.SetReportQuestionMutationVariables, TContext>) =>
    useMutation<Types.SetReportQuestionMutation, TError, Types.SetReportQuestionMutationVariables, TContext>(
      ['setReportQuestion'],
      (variables?: Types.SetReportQuestionMutationVariables) => gqlFetcher<Types.SetReportQuestionMutation, Types.SetReportQuestionMutationVariables>(SetReportQuestionDocument, variables)(),
      options
    );
useSetReportQuestionMutation.fetcher = (variables: Types.SetReportQuestionMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.SetReportQuestionMutation, Types.SetReportQuestionMutationVariables>(SetReportQuestionDocument, variables, options);
export const SetReportAnswerDocument = `
    mutation setReportAnswer($request: AnswerReportRequestInput!) {
  reportAnswer(request: $request)
}
    `;
export const useSetReportAnswerMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetReportAnswerMutation, TError, Types.SetReportAnswerMutationVariables, TContext>) =>
    useMutation<Types.SetReportAnswerMutation, TError, Types.SetReportAnswerMutationVariables, TContext>(
      ['setReportAnswer'],
      (variables?: Types.SetReportAnswerMutationVariables) => gqlFetcher<Types.SetReportAnswerMutation, Types.SetReportAnswerMutationVariables>(SetReportAnswerDocument, variables)(),
      options
    );
useSetReportAnswerMutation.fetcher = (variables: Types.SetReportAnswerMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.SetReportAnswerMutation, Types.SetReportAnswerMutationVariables>(SetReportAnswerDocument, variables, options);
export const SetQuestionLikeDocument = `
    mutation setQuestionLike($questionPid: ID!) {
  addQuestionLike(questionPid: $questionPid)
}
    `;
export const useSetQuestionLikeMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetQuestionLikeMutation, TError, Types.SetQuestionLikeMutationVariables, TContext>) =>
    useMutation<Types.SetQuestionLikeMutation, TError, Types.SetQuestionLikeMutationVariables, TContext>(
      ['setQuestionLike'],
      (variables?: Types.SetQuestionLikeMutationVariables) => gqlFetcher<Types.SetQuestionLikeMutation, Types.SetQuestionLikeMutationVariables>(SetQuestionLikeDocument, variables)(),
      options
    );
useSetQuestionLikeMutation.fetcher = (variables: Types.SetQuestionLikeMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.SetQuestionLikeMutation, Types.SetQuestionLikeMutationVariables>(SetQuestionLikeDocument, variables, options);
export const SetQuestionDisLikeDocument = `
    mutation setQuestionDisLike($questionPid: ID!) {
  deleteQuestionLike(questionPid: $questionPid)
}
    `;
export const useSetQuestionDisLikeMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetQuestionDisLikeMutation, TError, Types.SetQuestionDisLikeMutationVariables, TContext>) =>
    useMutation<Types.SetQuestionDisLikeMutation, TError, Types.SetQuestionDisLikeMutationVariables, TContext>(
      ['setQuestionDisLike'],
      (variables?: Types.SetQuestionDisLikeMutationVariables) => gqlFetcher<Types.SetQuestionDisLikeMutation, Types.SetQuestionDisLikeMutationVariables>(SetQuestionDisLikeDocument, variables)(),
      options
    );
useSetQuestionDisLikeMutation.fetcher = (variables: Types.SetQuestionDisLikeMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.SetQuestionDisLikeMutation, Types.SetQuestionDisLikeMutationVariables>(SetQuestionDisLikeDocument, variables, options);
export const SetAnswerLikeDocument = `
    mutation setAnswerLike($request: AnswerLikeRequestInput!) {
  addAnswerLike(request: $request)
}
    `;
export const useSetAnswerLikeMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetAnswerLikeMutation, TError, Types.SetAnswerLikeMutationVariables, TContext>) =>
    useMutation<Types.SetAnswerLikeMutation, TError, Types.SetAnswerLikeMutationVariables, TContext>(
      ['setAnswerLike'],
      (variables?: Types.SetAnswerLikeMutationVariables) => gqlFetcher<Types.SetAnswerLikeMutation, Types.SetAnswerLikeMutationVariables>(SetAnswerLikeDocument, variables)(),
      options
    );
useSetAnswerLikeMutation.fetcher = (variables: Types.SetAnswerLikeMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.SetAnswerLikeMutation, Types.SetAnswerLikeMutationVariables>(SetAnswerLikeDocument, variables, options);
export const SetDeleteAnswerLikeDocument = `
    mutation setDeleteAnswerLike($answerPid: ID!) {
  deleteAnswerLike(answerPid: $answerPid)
}
    `;
export const useSetDeleteAnswerLikeMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetDeleteAnswerLikeMutation, TError, Types.SetDeleteAnswerLikeMutationVariables, TContext>) =>
    useMutation<Types.SetDeleteAnswerLikeMutation, TError, Types.SetDeleteAnswerLikeMutationVariables, TContext>(
      ['setDeleteAnswerLike'],
      (variables?: Types.SetDeleteAnswerLikeMutationVariables) => gqlFetcher<Types.SetDeleteAnswerLikeMutation, Types.SetDeleteAnswerLikeMutationVariables>(SetDeleteAnswerLikeDocument, variables)(),
      options
    );
useSetDeleteAnswerLikeMutation.fetcher = (variables: Types.SetDeleteAnswerLikeMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.SetDeleteAnswerLikeMutation, Types.SetDeleteAnswerLikeMutationVariables>(SetDeleteAnswerLikeDocument, variables, options);
export const GetQnaCategoryGroupDocument = `
    query getQnaCategoryGroup {
  questionCategoryGroups {
    key
    name
    questionCategories {
      aiAnswerAvailable
      contentPlaceholder
      description
      iconImageUrl
      name
      questionCategoryId
      tags {
        name
      }
      titlePlaceholder
    }
  }
}
    `;
export const useGetQnaCategoryGroupQuery = <
      TData = Types.GetQnaCategoryGroupQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.GetQnaCategoryGroupQueryVariables,
      options?: UseQueryOptions<Types.GetQnaCategoryGroupQuery, TError, TData>
    ) =>
    useQuery<Types.GetQnaCategoryGroupQuery, TError, TData>(
      variables === undefined ? ['getQnaCategoryGroup'] : ['getQnaCategoryGroup', variables],
      gqlFetcher<Types.GetQnaCategoryGroupQuery, Types.GetQnaCategoryGroupQueryVariables>(GetQnaCategoryGroupDocument, variables),
      options
    );

useGetQnaCategoryGroupQuery.getKey = (variables?: Types.GetQnaCategoryGroupQueryVariables) => variables === undefined ? ['getQnaCategoryGroup'] : ['getQnaCategoryGroup', variables];
;

useGetQnaCategoryGroupQuery.fetcher = (variables?: Types.GetQnaCategoryGroupQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetQnaCategoryGroupQuery, Types.GetQnaCategoryGroupQueryVariables>(GetQnaCategoryGroupDocument, variables, options);
export const GetNewestQuestionCardCursorPageDocument = `
    query getNewestQuestionCardCursorPage($request: NewestQuestionCardCursorPageRequestInput!) {
  newestQuestionCardCursorPage(request: $request) {
    elements {
      answer {
        answerPid
        answerer {
          affiliatedInstitutionName
          answererId
          answererType
          name
          professionalFields {
            name
            professionalFieldId
            professionalFieldType
          }
          profileImageUrl
        }
        content
        createdAt
        dislikeCount
        footer
        header
        isBlockedByUser
        likeCount
        myLikeType
      }
      answeredStatus
      answererCount
      answererProfileImageUrls
      question {
        status
        accessibleType
        attachments {
          blindType
          questionAttachmentId
        }
        category {
          name
        }
        content
        createdAt
        isBlockedByMe
        isLikedByMe
        isMine
        likeCount
        questionPid
        tags {
          name
        }
        title
        user {
          name
          userId
        }
      }
    }
    pivot
  }
}
    `;
export const useGetNewestQuestionCardCursorPageQuery = <
      TData = Types.GetNewestQuestionCardCursorPageQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetNewestQuestionCardCursorPageQueryVariables,
      options?: UseQueryOptions<Types.GetNewestQuestionCardCursorPageQuery, TError, TData>
    ) =>
    useQuery<Types.GetNewestQuestionCardCursorPageQuery, TError, TData>(
      ['getNewestQuestionCardCursorPage', variables],
      gqlFetcher<Types.GetNewestQuestionCardCursorPageQuery, Types.GetNewestQuestionCardCursorPageQueryVariables>(GetNewestQuestionCardCursorPageDocument, variables),
      options
    );

useGetNewestQuestionCardCursorPageQuery.getKey = (variables: Types.GetNewestQuestionCardCursorPageQueryVariables) => ['getNewestQuestionCardCursorPage', variables];
;

useGetNewestQuestionCardCursorPageQuery.fetcher = (variables: Types.GetNewestQuestionCardCursorPageQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetNewestQuestionCardCursorPageQuery, Types.GetNewestQuestionCardCursorPageQueryVariables>(GetNewestQuestionCardCursorPageDocument, variables, options);
export const GetKeywordQuestionCardCursorPageDocument = `
    query getKeywordQuestionCardCursorPage($request: KeywordSearchQuestionCardCursorPageRequestInput!) {
  keywordSearchQuestionCardCursorPage(request: $request) {
    elements {
      answer {
        answerPid
        answerer {
          affiliatedInstitutionName
          answererId
          answererType
          name
          professionalFields {
            name
            professionalFieldId
            professionalFieldType
          }
          profileImageUrl
        }
        content
        createdAt
        dislikeCount
        footer
        header
        isBlockedByUser
        likeCount
        myLikeType
      }
      answeredStatus
      answererCount
      answererProfileImageUrls
      question {
        status
        accessibleType
        attachments {
          blindType
          questionAttachmentId
        }
        category {
          name
        }
        content
        createdAt
        isBlockedByMe
        isLikedByMe
        isMine
        likeCount
        questionPid
        tags {
          name
        }
        title
        user {
          name
          userId
        }
      }
    }
    pivot
  }
}
    `;
export const useGetKeywordQuestionCardCursorPageQuery = <
      TData = Types.GetKeywordQuestionCardCursorPageQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetKeywordQuestionCardCursorPageQueryVariables,
      options?: UseQueryOptions<Types.GetKeywordQuestionCardCursorPageQuery, TError, TData>
    ) =>
    useQuery<Types.GetKeywordQuestionCardCursorPageQuery, TError, TData>(
      ['getKeywordQuestionCardCursorPage', variables],
      gqlFetcher<Types.GetKeywordQuestionCardCursorPageQuery, Types.GetKeywordQuestionCardCursorPageQueryVariables>(GetKeywordQuestionCardCursorPageDocument, variables),
      options
    );

useGetKeywordQuestionCardCursorPageQuery.getKey = (variables: Types.GetKeywordQuestionCardCursorPageQueryVariables) => ['getKeywordQuestionCardCursorPage', variables];
;

useGetKeywordQuestionCardCursorPageQuery.fetcher = (variables: Types.GetKeywordQuestionCardCursorPageQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetKeywordQuestionCardCursorPageQuery, Types.GetKeywordQuestionCardCursorPageQueryVariables>(GetKeywordQuestionCardCursorPageDocument, variables, options);
export const GetMyQuestionCardCursorPageMobileDocument = `
    query getMyQuestionCardCursorPageMobile($request: MyQuestionCardCursorPageRequestInput!) {
  myQuestionCardCursorPage(request: $request) {
    elements {
      paymentTotal {
        currentAmount
        payTrackingNumber
        reason
        status
        userPaymentId
        userPayment {
          cardName
          cardNumber
          id
        }
      }
      answer {
        answerPid
        answerer {
          affiliatedInstitutionName
          answererId
          answererType
          name
          professionalFields {
            name
            professionalFieldId
            professionalFieldType
          }
          profileImageUrl
        }
        content
        createdAt
        dislikeCount
        footer
        header
        isBlockedByUser
        likeCount
        myLikeType
      }
      answeredStatus
      answererCount
      answererProfileImageUrls
      question {
        status
        accessibleType
        attachments {
          blindType
          questionAttachmentId
        }
        category {
          name
        }
        content
        createdAt
        isBlockedByMe
        isLikedByMe
        isMine
        likeCount
        questionPid
        tags {
          name
        }
        title
        user {
          name
          userId
        }
      }
    }
    pivot
  }
}
    `;
export const useGetMyQuestionCardCursorPageMobileQuery = <
      TData = Types.GetMyQuestionCardCursorPageMobileQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetMyQuestionCardCursorPageMobileQueryVariables,
      options?: UseQueryOptions<Types.GetMyQuestionCardCursorPageMobileQuery, TError, TData>
    ) =>
    useQuery<Types.GetMyQuestionCardCursorPageMobileQuery, TError, TData>(
      ['getMyQuestionCardCursorPageMobile', variables],
      gqlFetcher<Types.GetMyQuestionCardCursorPageMobileQuery, Types.GetMyQuestionCardCursorPageMobileQueryVariables>(GetMyQuestionCardCursorPageMobileDocument, variables),
      options
    );

useGetMyQuestionCardCursorPageMobileQuery.getKey = (variables: Types.GetMyQuestionCardCursorPageMobileQueryVariables) => ['getMyQuestionCardCursorPageMobile', variables];
;

useGetMyQuestionCardCursorPageMobileQuery.fetcher = (variables: Types.GetMyQuestionCardCursorPageMobileQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetMyQuestionCardCursorPageMobileQuery, Types.GetMyQuestionCardCursorPageMobileQueryVariables>(GetMyQuestionCardCursorPageMobileDocument, variables, options);
export const GetMyLikedQuestionCardCursorPageDocument = `
    query getMyLikedQuestionCardCursorPage($request: MyLikedQuestionCardCursorPageRequestInput!) {
  myLikedQuestionCardCursorPage(request: $request) {
    elements {
      answer {
        answerPid
        answerer {
          affiliatedInstitutionName
          answererId
          answererType
          name
          professionalFields {
            name
            professionalFieldId
            professionalFieldType
          }
          profileImageUrl
        }
        content
        createdAt
        dislikeCount
        footer
        header
        isBlockedByUser
        likeCount
        myLikeType
      }
      answeredStatus
      answererCount
      answererProfileImageUrls
      question {
        status
        accessibleType
        attachments {
          blindType
          questionAttachmentId
        }
        category {
          name
        }
        content
        createdAt
        isBlockedByMe
        isLikedByMe
        isMine
        likeCount
        questionPid
        tags {
          name
        }
        title
        user {
          name
          userId
        }
      }
    }
    pivot
  }
}
    `;
export const useGetMyLikedQuestionCardCursorPageQuery = <
      TData = Types.GetMyLikedQuestionCardCursorPageQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetMyLikedQuestionCardCursorPageQueryVariables,
      options?: UseQueryOptions<Types.GetMyLikedQuestionCardCursorPageQuery, TError, TData>
    ) =>
    useQuery<Types.GetMyLikedQuestionCardCursorPageQuery, TError, TData>(
      ['getMyLikedQuestionCardCursorPage', variables],
      gqlFetcher<Types.GetMyLikedQuestionCardCursorPageQuery, Types.GetMyLikedQuestionCardCursorPageQueryVariables>(GetMyLikedQuestionCardCursorPageDocument, variables),
      options
    );

useGetMyLikedQuestionCardCursorPageQuery.getKey = (variables: Types.GetMyLikedQuestionCardCursorPageQueryVariables) => ['getMyLikedQuestionCardCursorPage', variables];
;

useGetMyLikedQuestionCardCursorPageQuery.fetcher = (variables: Types.GetMyLikedQuestionCardCursorPageQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetMyLikedQuestionCardCursorPageQuery, Types.GetMyLikedQuestionCardCursorPageQueryVariables>(GetMyLikedQuestionCardCursorPageDocument, variables, options);
export const QnaSimpleSurveyAnswerDocument = `
    mutation QnaSimpleSurveyAnswer($request: SimpleSurveyAnswerAddRequestInput!) {
  addSimpleSurveyAnswer(request: $request)
}
    `;
export const useQnaSimpleSurveyAnswerMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.QnaSimpleSurveyAnswerMutation, TError, Types.QnaSimpleSurveyAnswerMutationVariables, TContext>) =>
    useMutation<Types.QnaSimpleSurveyAnswerMutation, TError, Types.QnaSimpleSurveyAnswerMutationVariables, TContext>(
      ['QnaSimpleSurveyAnswer'],
      (variables?: Types.QnaSimpleSurveyAnswerMutationVariables) => gqlFetcher<Types.QnaSimpleSurveyAnswerMutation, Types.QnaSimpleSurveyAnswerMutationVariables>(QnaSimpleSurveyAnswerDocument, variables)(),
      options
    );
useQnaSimpleSurveyAnswerMutation.fetcher = (variables: Types.QnaSimpleSurveyAnswerMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.QnaSimpleSurveyAnswerMutation, Types.QnaSimpleSurveyAnswerMutationVariables>(QnaSimpleSurveyAnswerDocument, variables, options);
export const GetFrequentSearchKeywordsDocument = `
    query GetFrequentSearchKeywords {
  frequentSearchKeywords
}
    `;
export const useGetFrequentSearchKeywordsQuery = <
      TData = Types.GetFrequentSearchKeywordsQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.GetFrequentSearchKeywordsQueryVariables,
      options?: UseQueryOptions<Types.GetFrequentSearchKeywordsQuery, TError, TData>
    ) =>
    useQuery<Types.GetFrequentSearchKeywordsQuery, TError, TData>(
      variables === undefined ? ['GetFrequentSearchKeywords'] : ['GetFrequentSearchKeywords', variables],
      gqlFetcher<Types.GetFrequentSearchKeywordsQuery, Types.GetFrequentSearchKeywordsQueryVariables>(GetFrequentSearchKeywordsDocument, variables),
      options
    );

useGetFrequentSearchKeywordsQuery.getKey = (variables?: Types.GetFrequentSearchKeywordsQueryVariables) => variables === undefined ? ['GetFrequentSearchKeywords'] : ['GetFrequentSearchKeywords', variables];
;

useGetFrequentSearchKeywordsQuery.fetcher = (variables?: Types.GetFrequentSearchKeywordsQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetFrequentSearchKeywordsQuery, Types.GetFrequentSearchKeywordsQueryVariables>(GetFrequentSearchKeywordsDocument, variables, options);
export const GetRelatedQuestionDocument = `
    query GetRelatedQuestion($similarSearchQuestionInput: SimilarSearchQuestionCardCursorPageRequestInput!) {
  similarSearchQuestionCardCursorPageRequest(request: $similarSearchQuestionInput) {
    elements {
      answeredStatus
      answererCount
      answererProfileImageUrls
      question {
        title
        status
        content
        category {
          name
        }
        attachments {
          blindType
          questionAttachmentId
        }
        questionPid
      }
    }
  }
}
    `;
export const useGetRelatedQuestionQuery = <
      TData = Types.GetRelatedQuestionQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetRelatedQuestionQueryVariables,
      options?: UseQueryOptions<Types.GetRelatedQuestionQuery, TError, TData>
    ) =>
    useQuery<Types.GetRelatedQuestionQuery, TError, TData>(
      ['GetRelatedQuestion', variables],
      gqlFetcher<Types.GetRelatedQuestionQuery, Types.GetRelatedQuestionQueryVariables>(GetRelatedQuestionDocument, variables),
      options
    );

useGetRelatedQuestionQuery.getKey = (variables: Types.GetRelatedQuestionQueryVariables) => ['GetRelatedQuestion', variables];
;

useGetRelatedQuestionQuery.fetcher = (variables: Types.GetRelatedQuestionQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetRelatedQuestionQuery, Types.GetRelatedQuestionQueryVariables>(GetRelatedQuestionDocument, variables, options);
export const GetQuestionDetailWithAiAnswerDocument = `
    query getQuestionDetailWithAiAnswer($questionPid: ID!) {
  question(questionPid: $questionPid) {
    attachments {
      blindType
      questionAttachmentId
    }
    content
    questionPid
    title
    aiAnswerStatus
    category {
      name
    }
    aiAnswer {
      aiAnswerStatus
      content
      questionPid
    }
    aiAnswerButtonType
    pregnant
    status
  }
}
    `;
export const useGetQuestionDetailWithAiAnswerQuery = <
      TData = Types.GetQuestionDetailWithAiAnswerQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetQuestionDetailWithAiAnswerQueryVariables,
      options?: UseQueryOptions<Types.GetQuestionDetailWithAiAnswerQuery, TError, TData>
    ) =>
    useQuery<Types.GetQuestionDetailWithAiAnswerQuery, TError, TData>(
      ['getQuestionDetailWithAiAnswer', variables],
      gqlFetcher<Types.GetQuestionDetailWithAiAnswerQuery, Types.GetQuestionDetailWithAiAnswerQueryVariables>(GetQuestionDetailWithAiAnswerDocument, variables),
      options
    );

useGetQuestionDetailWithAiAnswerQuery.getKey = (variables: Types.GetQuestionDetailWithAiAnswerQueryVariables) => ['getQuestionDetailWithAiAnswer', variables];
;

useGetQuestionDetailWithAiAnswerQuery.fetcher = (variables: Types.GetQuestionDetailWithAiAnswerQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetQuestionDetailWithAiAnswerQuery, Types.GetQuestionDetailWithAiAnswerQueryVariables>(GetQuestionDetailWithAiAnswerDocument, variables, options);
export const GetMyUncheckedQuestionDocument = `
    query GetMyUncheckedQuestion {
  myUncheckedQuestion {
    aiAnswerStatus
    questionPid
    status
    paymentInfo {
      discountAmount
      paidAmount
      paidAt
      payTryCount
      price
      usedPoint
    }
    paymentTotal {
      currentAmount
      payTrackingNumber
      reason
      status
      userPaymentId
      userPayment {
        cardName
        cardNumber
        id
      }
    }
  }
}
    `;
export const useGetMyUncheckedQuestionQuery = <
      TData = Types.GetMyUncheckedQuestionQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.GetMyUncheckedQuestionQueryVariables,
      options?: UseQueryOptions<Types.GetMyUncheckedQuestionQuery, TError, TData>
    ) =>
    useQuery<Types.GetMyUncheckedQuestionQuery, TError, TData>(
      variables === undefined ? ['GetMyUncheckedQuestion'] : ['GetMyUncheckedQuestion', variables],
      gqlFetcher<Types.GetMyUncheckedQuestionQuery, Types.GetMyUncheckedQuestionQueryVariables>(GetMyUncheckedQuestionDocument, variables),
      options
    );

useGetMyUncheckedQuestionQuery.getKey = (variables?: Types.GetMyUncheckedQuestionQueryVariables) => variables === undefined ? ['GetMyUncheckedQuestion'] : ['GetMyUncheckedQuestion', variables];
;

useGetMyUncheckedQuestionQuery.fetcher = (variables?: Types.GetMyUncheckedQuestionQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetMyUncheckedQuestionQuery, Types.GetMyUncheckedQuestionQueryVariables>(GetMyUncheckedQuestionDocument, variables, options);
export const QnaDecideAiAnswerDocument = `
    mutation QnaDecideAiAnswer($request: DecideAiAnswerRequestInput!) {
  decideAiAnswer(request: $request)
}
    `;
export const useQnaDecideAiAnswerMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.QnaDecideAiAnswerMutation, TError, Types.QnaDecideAiAnswerMutationVariables, TContext>) =>
    useMutation<Types.QnaDecideAiAnswerMutation, TError, Types.QnaDecideAiAnswerMutationVariables, TContext>(
      ['QnaDecideAiAnswer'],
      (variables?: Types.QnaDecideAiAnswerMutationVariables) => gqlFetcher<Types.QnaDecideAiAnswerMutation, Types.QnaDecideAiAnswerMutationVariables>(QnaDecideAiAnswerDocument, variables)(),
      options
    );
useQnaDecideAiAnswerMutation.fetcher = (variables: Types.QnaDecideAiAnswerMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.QnaDecideAiAnswerMutation, Types.QnaDecideAiAnswerMutationVariables>(QnaDecideAiAnswerDocument, variables, options);
export const QnaGenerateAiAnswerDocument = `
    mutation QnaGenerateAiAnswer($request: GenerateAiAnswerRequestInput!) {
  generateAiAnswer(request: $request)
}
    `;
export const useQnaGenerateAiAnswerMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.QnaGenerateAiAnswerMutation, TError, Types.QnaGenerateAiAnswerMutationVariables, TContext>) =>
    useMutation<Types.QnaGenerateAiAnswerMutation, TError, Types.QnaGenerateAiAnswerMutationVariables, TContext>(
      ['QnaGenerateAiAnswer'],
      (variables?: Types.QnaGenerateAiAnswerMutationVariables) => gqlFetcher<Types.QnaGenerateAiAnswerMutation, Types.QnaGenerateAiAnswerMutationVariables>(QnaGenerateAiAnswerDocument, variables)(),
      options
    );
useQnaGenerateAiAnswerMutation.fetcher = (variables: Types.QnaGenerateAiAnswerMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.QnaGenerateAiAnswerMutation, Types.QnaGenerateAiAnswerMutationVariables>(QnaGenerateAiAnswerDocument, variables, options);
export const AddQuestionWithAiAnswerDocument = `
    mutation AddQuestionWithAiAnswer($request: QuestionAddRequestInput!) {
  addQuestionV2(request: $request) {
    aiAnswerAvailable
    questionPid
  }
}
    `;
export const useAddQuestionWithAiAnswerMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.AddQuestionWithAiAnswerMutation, TError, Types.AddQuestionWithAiAnswerMutationVariables, TContext>) =>
    useMutation<Types.AddQuestionWithAiAnswerMutation, TError, Types.AddQuestionWithAiAnswerMutationVariables, TContext>(
      ['AddQuestionWithAiAnswer'],
      (variables?: Types.AddQuestionWithAiAnswerMutationVariables) => gqlFetcher<Types.AddQuestionWithAiAnswerMutation, Types.AddQuestionWithAiAnswerMutationVariables>(AddQuestionWithAiAnswerDocument, variables)(),
      options
    );
useAddQuestionWithAiAnswerMutation.fetcher = (variables: Types.AddQuestionWithAiAnswerMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.AddQuestionWithAiAnswerMutation, Types.AddQuestionWithAiAnswerMutationVariables>(AddQuestionWithAiAnswerDocument, variables, options);
export const GetQnaDetailWithRelatedQnaDocument = `
    query getQnaDetailWithRelatedQna($questionPid: ID!, $size: Int) {
  question(questionPid: $questionPid) {
    answererCount
    answeredStatus
    answers {
      answerPid
      answerer {
        affiliatedInstitutionName
        answererId
        answererType
        name
        profileImageUrl
      }
      content
      createdAt
      dislikeCount
      footer
      header
      isBlockedByMe
      likeCount
      myLikeType
      recommendedMagazineGraph {
        description
        magazinePid
        title
        titleImageUrl
      }
    }
    attachments {
      blindType
      questionAttachmentId
    }
    category {
      name
      questionCategoryId
      type
    }
    content
    createdAt
    isBlockedByMe
    isLikedByMe
    isMine
    likeCount
    questionPid
    showUserPersonalInfo
    tags {
      name
    }
    title
    user {
      name
      userId
      birth
      gender
    }
  }
  similarSearchQuestionCardCursorPageRequest(
    request: {questionPid: $questionPid, size: $size}
  ) {
    elements {
      answeredStatus
      answererCount
      answererProfileImageUrls
      question {
        status
        title
        content
        category {
          name
        }
        attachments {
          blindType
          questionAttachmentId
        }
        questionPid
      }
    }
  }
}
    `;
export const useGetQnaDetailWithRelatedQnaQuery = <
      TData = Types.GetQnaDetailWithRelatedQnaQuery,
      TError = Types.GraphqlError
    >(
      variables: Types.GetQnaDetailWithRelatedQnaQueryVariables,
      options?: UseQueryOptions<Types.GetQnaDetailWithRelatedQnaQuery, TError, TData>
    ) =>
    useQuery<Types.GetQnaDetailWithRelatedQnaQuery, TError, TData>(
      ['getQnaDetailWithRelatedQna', variables],
      gqlFetcher<Types.GetQnaDetailWithRelatedQnaQuery, Types.GetQnaDetailWithRelatedQnaQueryVariables>(GetQnaDetailWithRelatedQnaDocument, variables),
      options
    );

useGetQnaDetailWithRelatedQnaQuery.getKey = (variables: Types.GetQnaDetailWithRelatedQnaQueryVariables) => ['getQnaDetailWithRelatedQna', variables];
;

useGetQnaDetailWithRelatedQnaQuery.fetcher = (variables: Types.GetQnaDetailWithRelatedQnaQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetQnaDetailWithRelatedQnaQuery, Types.GetQnaDetailWithRelatedQnaQueryVariables>(GetQnaDetailWithRelatedQnaDocument, variables, options);
export const GetAgreementsByLocationCategoryDocument = `
    query getAgreementsByLocationCategory {
  getAgreementsByCategory(category: LOCATION_BASED_SERVICE) {
    id
    title
    url
  }
}
    `;
export const useGetAgreementsByLocationCategoryQuery = <
      TData = Types.GetAgreementsByLocationCategoryQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.GetAgreementsByLocationCategoryQueryVariables,
      options?: UseQueryOptions<Types.GetAgreementsByLocationCategoryQuery, TError, TData>
    ) =>
    useQuery<Types.GetAgreementsByLocationCategoryQuery, TError, TData>(
      variables === undefined ? ['getAgreementsByLocationCategory'] : ['getAgreementsByLocationCategory', variables],
      gqlFetcher<Types.GetAgreementsByLocationCategoryQuery, Types.GetAgreementsByLocationCategoryQueryVariables>(GetAgreementsByLocationCategoryDocument, variables),
      options
    );

useGetAgreementsByLocationCategoryQuery.getKey = (variables?: Types.GetAgreementsByLocationCategoryQueryVariables) => variables === undefined ? ['getAgreementsByLocationCategory'] : ['getAgreementsByLocationCategory', variables];
;

useGetAgreementsByLocationCategoryQuery.fetcher = (variables?: Types.GetAgreementsByLocationCategoryQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetAgreementsByLocationCategoryQuery, Types.GetAgreementsByLocationCategoryQueryVariables>(GetAgreementsByLocationCategoryDocument, variables, options);
export const GetCheckApprovedByLocationCategoryDocument = `
    query getCheckApprovedByLocationCategory {
  checkApproved(category: LOCATION_BASED_SERVICE)
}
    `;
export const useGetCheckApprovedByLocationCategoryQuery = <
      TData = Types.GetCheckApprovedByLocationCategoryQuery,
      TError = Types.GraphqlError
    >(
      variables?: Types.GetCheckApprovedByLocationCategoryQueryVariables,
      options?: UseQueryOptions<Types.GetCheckApprovedByLocationCategoryQuery, TError, TData>
    ) =>
    useQuery<Types.GetCheckApprovedByLocationCategoryQuery, TError, TData>(
      variables === undefined ? ['getCheckApprovedByLocationCategory'] : ['getCheckApprovedByLocationCategory', variables],
      gqlFetcher<Types.GetCheckApprovedByLocationCategoryQuery, Types.GetCheckApprovedByLocationCategoryQueryVariables>(GetCheckApprovedByLocationCategoryDocument, variables),
      options
    );

useGetCheckApprovedByLocationCategoryQuery.getKey = (variables?: Types.GetCheckApprovedByLocationCategoryQueryVariables) => variables === undefined ? ['getCheckApprovedByLocationCategory'] : ['getCheckApprovedByLocationCategory', variables];
;

useGetCheckApprovedByLocationCategoryQuery.fetcher = (variables?: Types.GetCheckApprovedByLocationCategoryQueryVariables, options?: RequestInit['headers']) => gqlFetcher<Types.GetCheckApprovedByLocationCategoryQuery, Types.GetCheckApprovedByLocationCategoryQueryVariables>(GetCheckApprovedByLocationCategoryDocument, variables, options);
export const SetApproveAgreementsDocument = `
    mutation setApproveAgreements($request: AgreementsApprovedApiRequestInput!) {
  approveAgreements(request: $request)
}
    `;
export const useSetApproveAgreementsMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.SetApproveAgreementsMutation, TError, Types.SetApproveAgreementsMutationVariables, TContext>) =>
    useMutation<Types.SetApproveAgreementsMutation, TError, Types.SetApproveAgreementsMutationVariables, TContext>(
      ['setApproveAgreements'],
      (variables?: Types.SetApproveAgreementsMutationVariables) => gqlFetcher<Types.SetApproveAgreementsMutation, Types.SetApproveAgreementsMutationVariables>(SetApproveAgreementsDocument, variables)(),
      options
    );
useSetApproveAgreementsMutation.fetcher = (variables: Types.SetApproveAgreementsMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.SetApproveAgreementsMutation, Types.SetApproveAgreementsMutationVariables>(SetApproveAgreementsDocument, variables, options);
export const UpdateQuestionLocationDocument = `
    mutation updateQuestionLocation($request: UpdateQuestionLocationRequestInput!) {
  updateQuestionLocation(request: $request)
}
    `;
export const useUpdateQuestionLocationMutation = <
      TError = Types.GraphqlError,
      TContext = unknown
    >(options?: UseMutationOptions<Types.UpdateQuestionLocationMutation, TError, Types.UpdateQuestionLocationMutationVariables, TContext>) =>
    useMutation<Types.UpdateQuestionLocationMutation, TError, Types.UpdateQuestionLocationMutationVariables, TContext>(
      ['updateQuestionLocation'],
      (variables?: Types.UpdateQuestionLocationMutationVariables) => gqlFetcher<Types.UpdateQuestionLocationMutation, Types.UpdateQuestionLocationMutationVariables>(UpdateQuestionLocationDocument, variables)(),
      options
    );
useUpdateQuestionLocationMutation.fetcher = (variables: Types.UpdateQuestionLocationMutationVariables, options?: RequestInit['headers']) => gqlFetcher<Types.UpdateQuestionLocationMutation, Types.UpdateQuestionLocationMutationVariables>(UpdateQuestionLocationDocument, variables, options);